<template>
  <div class="panel-container">
    <!-- 제목 -->
    <div class="card-title grid">
      <div>
        <div class="flex-align">
          <div class="subtitle2 main">{{ card.name }}</div>
          <div class="body1 sub3" style="margin-left:16px">{{ card.key }}.launchpack.co.kr</div>
        </div>
        <div class="flex">
          <div class="tag-temp body6-medium">임시저장</div>
        </div>
      </div>
      <div class="flex" style="justify-content: flex-end">
        <button class="button is-dark button-edit body4-medium" style="height:36px;" @click="clickEdit(3)">디자인 편집</button>
        <button class="button is-gray button-edit body4-medium" style="height:36px;"  @click="clickDelete">삭제</button>
      </div>
    </div>
    <!-- 내용 -->
    <div>
      <div class="h8 sub2" style="margin-bottom:8px">서비스 도메인</div>
      <div class="body2 sub4">서비스 개설 후 도메인 연결이 가능합니다.</div>

      <div class="grid" style="margin:32px 0 60px 0">
        <div>
          <div class="flex-between" style="margin-bottom:12px">
            <div class="h8 sub2">서비스팩</div>
            <div class="size-15 primary unselect"
                 style="text-decoration: underline" @click="clickEdit(1)">편집</div>
          </div>

          <dr-temporay :list="card.conversions" :visibleDelete="false"
                       @delete="item=>deleteItem('conversions',item)"></dr-temporay>
        </div>
        <div>
          <div class="flex-between" style="margin-bottom:12px">
            <div class="h8 sub2">플러그인</div>
            <div class="size-15 primary unselect" v-if="card.plugins.length>0"
                 style="text-decoration: underline" @click="clickEdit(2)">편집</div>
          </div>
          <dr-temporay :list="card.plugins"
                       @add="clickEdit(2)"
                       @delete="item=>deleteItem('plugins',item)"></dr-temporay>
        </div>
      </div>
    </div>
    <!-- 하단 -->
    <div class="flex-between">
      <div class="subtitle3 main"><span class="price h3">{{ card.price|currencyNum }}</span>원</div>
      <div class="flex">
        <button class="button-primary button is-primary-light flex-align" @click="openPreview">
          <img class="icon-device" src="/static/img/ic_device_mobile.svg">
          <span class="body2-medium">모바일 미리보기</span>
        </button>
        <a :href="`https://sbpreview.launchpack.co.kr/super_builder/preview?key=${card.key}&id=${card.id}&device=pc&package=${this.card.conversions[0].key}`" target="_blank">
          <button class="button-primary button is-primary-light flex-align">
            <img class="icon-device" src="/static/img/ic_device_pc.svg">
            <span class="body2-medium">데스크톱 미리보기</span>
          </button>
        </a>
        <button class="button-primary button is-primary body2-medium" @click="$emit('create')">개설하기</button>
      </div>
    </div>
  </div>
</template>

<script>
  import DrTemporay from "./DrTemporay";
  export default {
    name: "CardTemporaryPc",
    components: {DrTemporay},
    created() {

    },
    props: {
      card: {
        type: Object
      }
    },
    data() {
      return {

      }
    },
    methods: {
      clickDelete() {
        this.$emit('delete');
      },
      clickEdit(step) {
        this.$emit('edit', step);
      },
      openPreview() {
        let url = `https://sbpreview.launchpack.co.kr/super_builder/preview?key=${this.card.key}&id=${this.card.id}&device=mobile&package=${this.card.conversions[0].key}`;
        window.open(url, '_blank', 'width=434, height=736, top=50, left=100, fullscreen=no, ' +
          'menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      },
      deleteItem(type, item) {
        this.$emit('deleteItem', {
          service: this.card,
          type: type,
          item: item
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .panel-container
    margin 20px 0

  .card-title
    padding-bottom 28px
    border-bottom 1px solid $gray2
    margin-bottom 32px

  .tag-temp
    border-radius 4px
    background-color $primary-light2
    color $primary
    padding 3px 8px
    margin-top 8px

  .button-edit
    width 120px
    margin-left 16px

  .button-primary
    width 200px
    margin-left 16px

  .icon-device
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)
    margin-right 8px

  .grid
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 24px
</style>
